.dashboardcode-bsmultiselect ul.form-control {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: calc(1.5em + (0.75rem + 2px));
  margin-bottom: 0;
  cursor: text;
  list-style-type: none;
}
.dashboardcode-bsmultiselect ul.form-control input {
  height: auto;
  padding: 0;
  margin: 0;
  font-weight: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
}
.dashboardcode-bsmultiselect ul.form-control.disabled {
  background-color: #e9ecef;
}
.dashboardcode-bsmultiselect ul.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.dashboardcode-bsmultiselect ul.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.dashboardcode-bsmultiselect ul.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.dashboardcode-bsmultiselect ul.form-control > li.badge {
  padding-left: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-padding-end: 0.5rem;
  padding-inline-end: 0.5rem;
}
.dashboardcode-bsmultiselect ul.form-control > li.badge button.btn-close {
  float: none;
  font-size: 0.8em;
}
.dashboardcode-bsmultiselect ul.form-control > li.badge span.disabled {
  opacity: 0.65;
}
.dashboardcode-bsmultiselect ul.form-control.focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.dashboardcode-bsmultiselect ul.form-control.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
.dashboardcode-bsmultiselect ul.form-control.form-control-sm input {
  font-size: 0.875rem;
}
.dashboardcode-bsmultiselect ul.form-control.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}
.dashboardcode-bsmultiselect ul.form-control.form-control-lg input {
  font-size: 1.25rem;
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:valid, .dashboardcode-bsmultiselect ul.form-control.is-valid {
  border-color: #198754;
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:valid.focus, .dashboardcode-bsmultiselect ul.form-control.is-valid.focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:invalid, .dashboardcode-bsmultiselect ul.form-control.is-invalid {
  border-color: #dc3545;
}
.was-validated .dashboardcode-bsmultiselect ul.form-control:invalid.focus, .dashboardcode-bsmultiselect ul.form-control.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li {
  display: block;
  width: 100%;
  padding: 0 0.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check {
  cursor: inherit;
  justify-content: flex-start;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check .form-check-label, .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check .form-check-input {
  cursor: inherit;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check .form-check-input.disabled ~ .form-check-label {
  color: 0.5;
}
.dashboardcode-bsmultiselect div.dropdown-menu > ul .hover {
  color: var(--primary);
  background-color: #e9ecef;
}
.dashboardcode-bsmultiselect div.dropdown-menu + div.alert-warning {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  z-index: 4;
  font-size: small;
}
.dashboardcode-bsmultiselect.input-group.input-group-sm ul.form-control {
  min-height: calc(1.5em + (0.5rem + 2px));
}
.dashboardcode-bsmultiselect.input-group.input-group-sm ul.form-control input {
  font-size: 0.875rem;
}
.dashboardcode-bsmultiselect.input-group.input-group-lg ul.form-control {
  min-height: calc(1.5em + (1rem + 2px));
}
.dashboardcode-bsmultiselect.input-group.input-group-lg ul.form-control input {
  font-size: 1.25rem;
}

.form-floating .dashboardcode-bsmultiselect ul.form-control {
  min-height: calc(3.5rem + 2px);
}
.form-floating .dashboardcode-bsmultiselect ul.form-control.floating-lifted {
  padding-top: 1.625rem;
  padding-left: 0.7rem;
  padding-bottom: 0;
}
.form-floating .dashboardcode-bsmultiselect + label.floating-lifted {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check-input:valid:checked ~ .form-check-label,
.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check-input:valid:not(:checked) ~ .form-check-label {
  color: #212529;
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li.hover .form-check-input:valid:checked ~ .form-check-label,
.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li.hover .form-check-input:valid:not(:checked) ~ .form-check-label {
  color: var(--bs-primary);
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check-input:valid:checked {
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
}

.was-validated .dashboardcode-bsmultiselect div.dropdown-menu > ul > li .form-check-input:valid:not(:checked) {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/*# sourceMappingURL=BsMultiSelect.css.map */